import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("./views/Home.vue")
    },
    {
        path: "/story",
        name: "story",
        component: () => import("./views/Story.vue")
    },
    {
        path: "/programma",
        name: "programma",
        component: () => import("./views/Programma.vue")
    },
    {
        path: "/memories",
        name: "memories",
        component: () => import("./views/Memories.vue")
    },
    {
        path: "/conferma",
        name: "conferma",
        component: () => import("./views/Conferma.vue")
    },
    {
        path: "/contatti",
        name: "contatti",
        component: () => import("./views/Contatti.vue")
    },
    {
        path: "/serenata",
        name: "serenata",
        component: () => import("./views/Serenata.vue")
    },
    {
        path: "/serenata/canzone",
        name: "canzone",
        component: () => import("./views/Canzone.vue"),
		props: (route) => ({ title: route.query.title, traduzione: route.query.traduzione })
    }
];

export default new VueRouter({
    //mode: "history",
    base: process.env.BASE_URL,
    routes: routes
});
