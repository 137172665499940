import Vuex from "vuex";
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        menu: [
            { icon: "mdi-home", title: "Home", route: "/" },
            { icon: "mdi-book-open-variant", title: "Story", route: "/story" },
            { icon: "mdi-calendar-text", title: "Programma", route: "/programma" },
            { icon: "mdi-image-text", title: "Memories", route: "/memories" },
            { icon: "mdi-account-check", title: "Conferma", route: "/conferma" },
            { icon: "mdi-contacts", title: "Contatti", route: "/contatti" }
        ]
    },

    getters: {
        
    },

    mutations: {

    }
});