<template>
    <v-app-bar :color="scrolled < 200 ? 'transparent' : '#000'" absolute dark :class="scrolled < 200 ? '' : 'headerAnim animate__animated animate__fadeInDown'">
        <template v-slot: extension>
            <v-tabs class="cntTab">
                <!-- <v-img src="./assets/logo.jpg"></v-img> -->
                <div class="tab" v-for="(item, index) in menu" :key="index">
                    <v-tab :to="item.route" @click.native="$scrollToTop">{{ item.title }}</v-tab>
                    <v-icon class="iconHeart" v-if="index < menu.length - 1" dark>
                        mdi-heart
                    </v-icon>
                </div>
            </v-tabs>
        </template>
    </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "TabView",

    props: ["scrolled"],

    data: () => ({}),

    computed: {
        ...mapState({
            menu: state => state.menu
        })
    }
};
</script>

<style lang="scss">
@import "../app";
.tab {
    display: flex;
    .iconHeart {
        font-size: 9px !important;
        margin-left: 3.5rem;
    }

    @media screen and (min-width: 1650px) {
        .iconHeart {
            margin-left: 4.5rem;
        }
    }
    @media screen and (min-width: 1024px) and (max-width: 1189px) {
        .iconHeart {
            margin-left: 1.2rem;
        }
    }
    @media screen and (min-width: 1190px) and (max-width: 1280px) {
        .iconHeart {
            margin-left: 2.3rem;
        }
    }
}
</style>
