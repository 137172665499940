<template>
  <v-app v-scroll="onScroll">
    <template v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
      <Drawer :scrolled="scrolled" />
    </template>

    <template v-else>
      <TabView :scrolled="scrolled" />
    </template>

    <router-view></router-view>

    <v-btn 
      color="#000" 
      dark 
      @click="onPlay" 
      class="mx-2 btnMute" 
      fab
      small>
      <v-icon v-if="!play">
        mdi-play
      </v-icon>
      <v-icon v-else>
        mdi-pause
      </v-icon>
    </v-btn>
    <audio loop id="testaudio" volume="0.2">
      <source src="./static/Someone You Loved (Piano_Cello) The Piano Guys.mp3" type="audio/mpeg" />
    </audio>
  </v-app>
</template>

<script>
import Drawer from './components/Drawer';
import TabView from './components/TabView';
import { mapState } from 'vuex';

export default {
  name: 'App',

  components: {
    Drawer,
    TabView
  },

  mounted() {
    document.getElementById("testaudio").volume = 0.2;
  },

  data: () => ({
    scrolled: 0,
    play: false
  }),

  computed: {
    ...mapState({
      menu: state => state.menu
    }),
  },

  methods: {
    onScroll(e) {
      this.scrolled = e.target.scrollingElement.scrollTop;
    },

    onPlay() {
      this.play = !this.play;
      if(this.play) document.getElementById("testaudio").play();
      else document.getElementById("testaudio").pause();
    }
  }
};
</script>

<style lang="scss">
  @import "./app";

  .btnMute {
    position: fixed;
    bottom: 0;
    margin-bottom: 8px;
  }
</style>
