import { GoogleSpreadsheet } from "google-spreadsheet";
import creds from "../config/matrimonio-338009-be8e89c20b7e.json";

export default {
    doc: null,
    spliImage: 20,

    async init() {
        this.doc = new GoogleSpreadsheet("1IjbB39nWfxvrExv4tDOTdxKF_gaRHT8aQLUzvKJY28Y");
        console.log(creds);
        await this.doc.useServiceAccountAuth(creds);
    },

    async addRow(fields) {
        var isName = await this.getRow(fields, 1);
        if (!isName) return false;
        var sheet = await this.getSheet(0);
        var row = await this.getRow(fields, 0);
        if (row) {
            row["Numero Invitati"] = fields.numInvitati;
            row["Nomi Invitati"] = fields.nomiInvitati;
            row["Commenti"] = fields.commenti;
            await row.save();
        } else {
            await sheet.setHeaderRow(["Nome", "Cognome", "Numero Invitati", "Nomi Invitati", "Commenti"]);
            await sheet.addRow({ Nome: fields.nome, Cognome: fields.cognome, "Numero Invitati": fields.numInvitati, "Nomi Invitati": fields.nomiInvitati, Commenti: fields.commenti });
        }
        return true;
    },

    async getRow(fields, index) {
        if (!fields.nome || !fields.cognome) return;
        var sheet = await this.getSheet(index);
        var rows = await sheet.getRows();
        var row = rows.find(item => {
            if (item["Nome"].toLowerCase().trim() == fields.nome.toLowerCase().trim() && item["Cognome"].toLowerCase().trim() == fields.cognome.toLowerCase().trim()) {
                return item;
            }
        });
        return row;
    },

    async getSheet(index) {
        await this.doc.loadInfo();
        return this.doc.sheetsByIndex[index];
    },

    async getToken() {
        var sheet = await this.getSheet(2);
        var rows = await sheet.getRows();
        if (rows.lenght == 0) return;
        return rows[0].Token;
    },

    async setToken(token) {
        var sheet = await this.getSheet(2);
        var rows = await sheet.getRows();
        if (rows.lenght == 0) return;
        rows[0].Token = token;
        await rows[0].save();
    },

    async addCommento(messaggio) {
        var sheet = await this.getSheet(3);
        await sheet.setHeaderRow(["Messaggio", "Data"]);
        await sheet.addRow({ Messaggio: messaggio.text, Data: messaggio.data });
    },

    async getCommenti() {
        var commenti = [];
        var sheet = await this.getSheet(3);
        var rows = await sheet.getRows();
        if (rows.lenght == 0) return;
        rows.forEach(item => {
            commenti.push(item);
        });
        return commenti.slice().reverse();
    },

    async getTestiCanzoni() {
        var sheet = await this.getSheet(4);
        var rows = await sheet.getRows();
        return rows;
    },

    async getCanzone(title) {
        var sheet = await this.getSheet(4);
        var rows = await sheet.getRows();
        if (rows.lenght == 0) return;
        var row = rows.find(item => {
            if (item.Titolo == title) {
                return item;
            }
        });
        return row;
    }
};
